import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Button = React.forwardRef(
  (
    {
      as,
      variant,
      type,
      disabled,
      newTab,
      isShrink,
      label,
      icon,
      href,
      ...props
    },
    ref
  ) => {
    let varClasses = '';

    switch (variant) {
      case 'secondary':
        varClasses =
          'text-current rounded-10 border-3 border-blue-bold hover:border-blue-bold-hover hover:underline active:border-blue-bold-active py-3 px-5 h-[48px]';
        break;
      case 'tertiary':
        varClasses = 'px-0 py-4 relative h-[50px]';
        break;
      case 'quaternary':
        varClasses =
          'text-current rounded-10 border-3 border-white hover:border-opacity-50 hover:underline active:border-blue-bold-active py-3 px-5 h-[48px]';
        break;
      case 'filter':
        varClasses =
          'text-current rounded-10 border-2 border-white hover:underline active:border-blue-bold-active active:bg-blue-bold-active py-3 px-5';
        break;
      case 'navigation':
        varClasses =
          'text-blue-bold bg-white rounded-full w-12 h-12 shadow-nav hover:shadow-default disabled:opacity-20';
        break;
      case 'social':
        varClasses = 'text-blue-bold hover:text-blue-navy rounded-md h-9';
        break;
      case 'social-white':
        varClasses = 'text-white hover:text-blue-navy rounded-md h-9';
        break;
      default:
        // primary
        varClasses =
          'text-white bg-blue-bold hover:bg-blue-bold-hover hover:shadow-hover hover:underline active:bg-blue-bold-active rounded-10 border-3 border-blue-bold hover:border-blue-bold-hover active:border-blue-bold-active py-3 px-5 h-[48px] disabled:bg-grey-disabled disabled:border-grey-disabled disabled:shadow-transparent disabled:no-underline';
        break;
    }

    const classes = cn(
      'inline-flex items-center justify-center transition group gap-x-2.5 text-body-sm',
      varClasses,
      props.className
    );

    if (ref) {
      props.ref = ref;
    }

    if (newTab) {
      props.target = '_blank';
      props.rel = 'noreferrer noopener';
    }

    if (as === 'button' && type) {
      props.type = type;
    }

    if (href) {
      props.href = href;
    }

    const Component = as;

    return (
      <Component {...props} className={classes} disabled={disabled}>
        {label && (
          <span
            className={cn(
              'text-sm leading-xs tracking-normal',
              isShrink ? 'mobile+tablet:hidden' : '',
              variant && variant === 'tertiary'
                ? 'transition-transform transform group-hover:-translate-y-[3px]'
                : ''
            )}
          >
            {label}
          </span>
        )}
        {React.isValidElement(icon) && icon}
        {variant && variant === 'tertiary' && (
          <span className="w-full bg-gradient-109 from-blue-navy to-blue-bold h-[3px] absolute bottom-0" />
        )}
      </Component>
    );
  }
);

Button.defaultProps = {
  as: 'button',
  variant: 'primary',
  type: 'button',
  disabled: false,
  newTab: false,
  isShrink: false,
  icon: null,
  href: '',
  label: '',
};

Button.propTypes = {
  as: PropTypes.elementType,
  variant: PropTypes.oneOf([
    '',
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'filter',
    'navigation',
    'social',
    'social-white',
  ]),
  type: PropTypes.oneOf(['', 'button', 'reset', 'submit']),
  disabled: PropTypes.bool,
  newTab: PropTypes.bool,
  isShrink: PropTypes.bool,
  href: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
};

export default Button;

import React from 'react';
import cn from 'classnames';

// Icons
import SimpleLogo from '../logos/logo-simple.svg';

const BackgroundIcon = ({ ...props }) => {
  const classArray = props.className.split(' ');

  const classes = cn(
    'bg-gradient-109 from-blue-navy to-blue-bold overflow-hidden',
    classArray.filter((val) => val !== 'absolute').join(' '),
    classArray.includes('absolute') ? 'absolute' : 'relative'
  );

  return (
    <div className={classes}>
      <img
        src={SimpleLogo}
        alt=""
        className="absolute bottom-0 -left-[16.667%] origin-bottom-left rotate-[28deg] w-full h-auto opacity-50"
      />
    </div>
  );
};

export default BackgroundIcon;

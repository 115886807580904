import React, { lazy, useEffect, useMemo, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Atoms
import Button from '../atoms/button';

export const phoneRegExp = new RegExp(/\D/g);

export const headerHeight = {
  mobile: 90,
  desktop: 120,
};

export const breakpoints = {
  xs: 375,
  sm: 450,
  md: 768,
  lg: 1024,
  xl: 1440,
};

export const generateUrlFromContentModel = (params) => {
  switch (params.__typename) {
    case 'ContentfulHomePage':
      return '/';
    case 'ContentfulBookADemoPage':
      return '/book-a-demo';
    case 'ContentfulContactPage':
      return '/contact';
    case 'ContentfulCareersPage':
      return '/careers';
    default:
      return `/${params?.slug}`;
  }
};

export const generateTelFromPhoneNumber = (obj) => {
  const strippedNumber = obj.value.replace(phoneRegExp, '');
  const withCountryCode = obj.countryCode + strippedNumber.slice(1);
  return withCountryCode;
};

const getScrollPosition = () => {
  if (typeof window === 'undefined') {
    return 0;
  }

  return (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  );
};

const shuffle = (array) => {
  let randomIndex;
  let currentIndex = array.length;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const shrinkArray = (arr, newLength) => {
  // const clonedArray = [...arr];
  // while (clonedArray.length > newLength) {
  //   const randomIndex = Math.floor(Math.random() * clonedArray.length);
  //   clonedArray.splice(randomIndex, 1);
  // }
  // return clonedArray;
  return arr.length > newLength ? shuffle(arr).slice(0, newLength) : arr;
};

export const useScroll = (wait = 250) => {
  const defaultScrollTop = useMemo(() => getScrollPosition(), []);
  const previousScrollTop = useRef(defaultScrollTop);
  const [currentScrollTop, setCurrentScrollTop] = useState(defaultScrollTop);

  useEffect(() => {
    const handleDocumentScroll = () => {
      const scrollTop = getScrollPosition();
      setCurrentScrollTop(scrollTop);
      previousScrollTop.current = scrollTop;
    };

    const handleDocumentScrollThrottled = throttle(handleDocumentScroll, wait);
    window.addEventListener('scroll', handleDocumentScrollThrottled);

    return () => {
      window.removeEventListener('scroll', handleDocumentScrollThrottled);
    };
  }, [wait]);

  return {
    scrollTop: currentScrollTop,
    previousScrollTop: previousScrollTop.current,
    time: wait,
  };
};

const getWindowDimensions = () => {
  if (typeof window === 'undefined') {
    return 0;
  }

  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const defaultWindowDimensions = useMemo(() => getWindowDimensions(), []);
  const [currentWindowDimensions, getCurrentWindowDimensions] = useState(
    defaultWindowDimensions
  );

  useEffect(() => {
    const handleResize = () => {
      const dimension = getWindowDimensions();
      getCurrentWindowDimensions(dimension);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return currentWindowDimensions;
};

export const richTextOptions = {
  renderText: (text) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
    [MARKS.UNDERLINE]: (text) => <span className="underline">{text}</span>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node;

      return (
        <a href={uri} aria-label={`Navigate to ${uri}`}>
          {children}
        </a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const {
        data: { target },
      } = node;

      return (
        <a
          href={generateUrlFromContentModel(target)}
          aria-label={`Navigate to ${target?.title} page`}
        >
          {children}
        </a>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const {
        data: { target },
      } = node;

      return (
        <a
          href={target?.gatsbyImageData?.images?.fallback?.src}
          aria-label={`Download ${target?.title} asset`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const {
        data: { target },
      } = node;

      return (
        <Button
          as="a"
          variant="tertiary"
          href={generateUrlFromContentModel(target)}
          label={target?.title}
          aria-label={`Navigate to ${target?.title} page`}
        />
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const {
        data: { target },
      } = node;

      const img = getImage(target);

      if (img) {
        return (
          <GatsbyImage
            image={img}
            // title={target?.title}
            alt={target?.description}
          />
        );
      }
      return null;
    },
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="space-y-1">{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="space-y-1">{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node) => {
      const transformedChildren = documentToReactComponents(node, {
        renderNode: {
          ...richTextOptions.renderNode,
          [BLOCKS.PARAGRAPH]: (n, children) => children,
          [BLOCKS.LIST_ITEM]: (n, children) => children,
        },
      });
      return (
        <li className="pl-6 relative text-body-sm before:text-body-sm before:absolute before:left-0">
          {transformedChildren}
        </li>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="u-h3">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="u-h4">{children}</h3>
    ),
  },
};

export const doesBlockHaveBG = (type) => {
  return [
    'ContentfulDmsBlock',
    'ContentfulCtaBlock',
    'ContentfulTestimonialBlock',
    'ContentfulTeamMemberBlock',
    'ContentfulFormBlock',
  ].includes(type);
};

export const renderIconBasedOnId = (name, cn) => {
  const PlaceholderIcon = lazy(() => import('../icons/icon-placeholder.svg'));
  const SolutionIcon = lazy(() => import('../icons/icon-solution.svg'));
  const GrowBusinessIcon = lazy(() =>
    import('../icons/icon-grow-business.svg')
  );
  const HappyIcon = lazy(() => import('../icons/icon-happy.svg'));
  const IntegratedIcon = lazy(() => import('../icons/icon-integrated.svg'));
  const ManageChangeIcon = lazy(() =>
    import('../icons/icon-manage-change.svg')
  );
  const MobileIcon = lazy(() => import('../icons/icon-mobile.svg'));
  const ReleasesIcon = lazy(() => import('../icons/icon-releases.svg'));
  const SupportIcon = lazy(() => import('../icons/icon-support.svg'));
  const TransparentIcon = lazy(() => import('../icons/icon-transparent.svg'));

  const MotorcycleIcon = lazy(() => import('../icons/icon-motorcycle.svg'));
  const AutomotiveIcon = lazy(() => import('../icons/icon-automotive.svg'));
  const MarineIcon = lazy(() => import('../icons/icon-marine.svg'));

  const KeyIcon = lazy(() => import('../icons/icon-key.svg'));
  const ProfileIcon = lazy(() => import('../icons/icon-profile.svg'));

  switch (name) {
    case 'All in one solution':
      return <SolutionIcon className={cn} />;
    case 'Grow business':
      return <GrowBusinessIcon className={cn} />;
    case 'Happy customers and teams':
      return <HappyIcon className={cn} />;
    case 'Integrated':
      return <IntegratedIcon className={cn} />;
    case 'Manage change':
      return <ManageChangeIcon className={cn} />;
    case 'Mobile':
      return <MobileIcon className={cn} />;
    case 'New releases':
      return <ReleasesIcon className={cn} />;
    case 'Support':
      return <SupportIcon className={cn} />;
    case 'Motorcycle':
      return <MotorcycleIcon className={cn} />;
    case 'Automotive':
      return <AutomotiveIcon className={cn} />;
    case 'Marine':
      return <MarineIcon className={cn} />;
    case 'Key':
      return <KeyIcon className={cn} />;
    case 'Profile':
      return <ProfileIcon className={cn} />;
    case 'Transparent':
      return <TransparentIcon className={cn} />;
    default:
      return <PlaceholderIcon className={cn} />;
  }
};

export default {
  phoneRegExp,
  headerHeight,
  breakpoints,
  generateUrlFromContentModel,
  useScroll,
  richTextOptions,
  doesBlockHaveBG,
  renderIconBasedOnId,
  shrinkArray,
};

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Atoms
import BackgroundIcon from '../atoms/background-icon';
import Button from '../atoms/button';

// Components
import {
  generateUrlFromContentModel,
  generateTelFromPhoneNumber,
} from './helpers';

// Forms
// import NewsletterForm from '../forms/newsletter-form';

// Logos
import Logo from '../logos/logo.png';
import AppStoreLogo from '../logos/logo-appstore.svg';
import GooglePlayLogo from '../logos/logo-googleplay.svg';

// Icons
import FacebookIcon from '../icons/icon-facebook.svg';
import TwitterIcon from '../icons/icon-twitter.svg';
import LinkedInIcon from '../icons/icon-linkedin.svg';
import YoutubeIcon from '../icons/icon-youtube.svg';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allContentfulGlobalOption(filter: { publishable: { eq: "Publish" } }) {
          nodes {
            facebook
            twitter
            linkedIn
            youtube
            appleStore
            googleStore
            footerPrimaryNavigations {
              ... on Node {
                ...homeNavFragment
                ...standardNavFragment
                ...builderNavFragment
                ...demoNavFragment
                ...contactNavFragment
                ...careersNavFragment
              }
            }
            footerSecondaryNavigations {
              ... on Node {
                ...homeNavFragment
                ...standardNavFragment
                ...builderNavFragment
                ...demoNavFragment
                ...contactNavFragment
                ...careersNavFragment
              }
            }
            footerTertiaryNavigations {
              ... on Node {
                ...homeNavFragment
                ...standardNavFragment
                ...builderNavFragment
                ...demoNavFragment
                ...contactNavFragment
                ...careersNavFragment
              }
            }
            phoneNumbers {
              displayName
              value
              countryCode
            }
            welcomeToCountry {
              text: welcomeToCountry
            }
          }
        }
      }
    `}
    render={(data) => {
      const params = data.allContentfulGlobalOption.nodes[0];

      return (
        <footer className="relative py-14 lg:py-16 bg-grey-light text-grey-font-2">
          <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
            <a
              href="/"
              className="block"
              aria-label="Navigate back to Rev's home page"
            >
              <img
                src={Logo}
                alt=""
                className="mobile+tablet:mx-auto mb-8 lg:mb-10 max-w-[200px] max-h-[23px]"
              />
            </a>
            <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8 mb-6 lg:mb-14">
              <div className="col-span-12 lg:col-span-6 mobile+tablet:text-center space-y-12 mb-20 lg:mb-0">
                {params.phoneNumbers.length > 0 && (
                  <div className="space-y-5 lg:space-y-6">
                    {params.phoneNumbers.map((content, key) => (
                      <p
                        className="u-body-sm font-bold"
                        key={`phone-number-${key}`}
                      >
                        {content.displayName}:{' '}
                        <a
                          href={`tel:${generateTelFromPhoneNumber(content)}`}
                          className="underline hover:no-underline text-blue-bold font-normal"
                          aria-label={`Call for ${content.displayName}`}
                        >
                          {content.value}
                        </a>
                      </p>
                    ))}
                  </div>
                )}
                {/* <NewsletterForm /> */}
              </div>
              <div className="col-span-12 lg:col-span-6 lg2:col-span-5">
                <div className="lg:flex lg:items-start lg:justify-between">
                  <p className="text-body-sm mb-6 mobile+tablet:text-center font-bold">
                    Dealership solutions for:
                  </p>
                  {params.footerPrimaryNavigations.length > 0 && (
                    <ul className="list-none mobile+tablet:text-center mobile+tablet:mt-6 space-y-6">
                      {params.footerPrimaryNavigations.map((nav, key) => (
                        <li
                          key={`footer-primary-nav-${key}`}
                          className="text-body-sm"
                        >
                          <a
                            href={generateUrlFromContentModel(nav)}
                            className="hover:underline text-blue-bold"
                            aria-label={`Navigating to ${nav.title} page`}
                          >
                            {nav.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                  {params.footerSecondaryNavigations.length > 0 && (
                    <ul className="list-none mobile+tablet:text-center mobile+tablet:mt-6 space-y-6">
                      {params.footerSecondaryNavigations.map((nav, key) => (
                        <li
                          key={`footer-secondary-nav-${key}`}
                          className="text-body-sm"
                        >
                          <a
                            href={generateUrlFromContentModel(nav)}
                            className="hover:underline text-blue-bold"
                            aria-label={`Navigating to ${nav.title} page`}
                          >
                            {nav.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:flex lg:items-center mb-3">
              <div className="mt-8 lg:mt-4 text-grey-font-1 lg:mr-28">
                {params.welcomeToCountry && (
                  <p className="text-body-xs text-center lg:text-left">
                    {params.welcomeToCountry.text}
                  </p>
                )}
              </div>
              {(params.appleStore || params.googleStore) && (
                <ul className="list-none flex justify-center items-center space-x-4 mobile+tablet:mt-6">
                  {params.appleStore && (
                    <li>
                      <a
                        href={params.appleStore}
                        className="block"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Navigating to Rev's app store page"
                      >
                        <img src={AppStoreLogo} alt="App Store Logo" />
                      </a>
                    </li>
                  )}
                  {params.googleStore && (
                    <li>
                      <a
                        href={params.googleStore}
                        className="block"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Navigating to Rev's google store page"
                      >
                        <img
                          src={GooglePlayLogo}
                          alt="Google Play Store Logo"
                        />
                      </a>
                    </li>
                  )}
                </ul>
              )}
              {(params.facebook ||
                params.twitter ||
                params.linkedIn ||
                params.youtube) && (
                <ul className="list-none text-none flex justify-center items-center space-x-4 mobile+tablet:mt-6 lg:ml-auto">
                  {params.facebook && (
                    <li>
                      <Button
                        as="a"
                        variant="social"
                        href={params.facebook}
                        aria-label="Navigating to Rev's facebook page"
                        newTab
                        icon={<FacebookIcon className="fill-current w-9 h-9" />}
                      />
                    </li>
                  )}
                  {params.twitter && (
                    <li>
                      <Button
                        as="a"
                        variant="social"
                        href={params.twitter}
                        aria-label="Navigating to Rev's twitter page"
                        newTab
                        icon={<TwitterIcon className="fill-current w-9 h-9" />}
                      />
                    </li>
                  )}
                  {params.linkedIn && (
                    <li>
                      <Button
                        as="a"
                        variant="social"
                        href={params.linkedIn}
                        aria-label="Navigating to Rev's linkedin page"
                        newTab
                        icon={<LinkedInIcon className="fill-current w-9 h-9" />}
                      />
                    </li>
                  )}
                  {params.youtube && (
                    <li>
                      <Button
                        as="a"
                        variant="social"
                        href={params.youtube}
                        aria-label="Navigating to Rev's youtube page"
                        newTab
                        icon={<YoutubeIcon className="fill-current w-9 h-9" />}
                      />
                    </li>
                  )}
                </ul>
              )}
            </div>
            <hr className="text-grey-mid mt-3 mb-2.5 lg:my-8" />
            <div className="text-left">
              {params.footerTertiaryNavigations.length > 0 && (
                <ul className="list-none flex justify-around lg:justify-start items-center mr-4 lg:mr-8 last:mr-0">
                  {params.footerTertiaryNavigations.map((nav, key) => (
                    <li
                      key={`footer-tertiary-nav-${key}`}
                      className="text-body-xs"
                    >
                      <a
                        href={generateUrlFromContentModel(nav)}
                        className="underline no-underline"
                        aria-label={`Navigating to ${nav.title} page`}
                      >
                        {nav.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <BackgroundIcon className="absolute bottom-0 left-0 right-0 h-6 w-full" />
        </footer>
      );
    }}
  />
);

export default Footer;
